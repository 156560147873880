import './App.css';
import {BrowserRouter} from 'react-router-dom'
import {Navbar,About,Contact,Experience,Feedbacks,Hero,Tech,Works,StarsCanvas} from './components'
function App() {
  return (
  <BrowserRouter>
   <div className='relative z-0 bg-primary'>
    <div className='bg-hero-pattern bg-cover bg-no-repeat bg-center'>
   <Navbar/>
   <Hero/>  
    </div>
    <About/>
    <Experience/>
    <Tech/>
    <Works/>
    {/* <Feedbacks/> */}
    <div className='relative z-0'>
    <Contact/>
    <StarsCanvas/>
    <div className='absolute z-0 '>
      <p className='text-purple-400'> © majidkhankhan639@gmail.com</p>
    </div>
    </div>
   </div>
  </BrowserRouter>
  );
} 

export default App;
